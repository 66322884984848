import generatedIcons from "app/components/icons/generatedIcons"
import cx from "classnames"
import React from "react"

export type IconName = keyof typeof generatedIcons
export const iconNames = Object.keys(generatedIcons) as IconName[]

// Things we might want here:
// - Aliases (to rename the icons, some of the material names are pretty far
//   from what we're doing with them.) Would be kinda nice to have all our icons
//   named semantically.
// - Overrides - a way to to default props for certain icons (size & viewBox.)

// Feel free to add anything else from React.SVGProps<SVGSVGElement> to this
interface Props {
  name: IconName
  size?: string | number
  viewBox?: string
  fill?: string
  className?: string
  style?: React.CSSProperties
}

const Icon = React.memo(({ name, size, className, ...props }: Props) => {
  const I = generatedIcons[name]
  const cn = cx("material-icon", `material-icon-${name}`, className)
  if (size) {
    return <I width={size} height={size} {...props} className={cn} />
  } else {
    // don't override default w/h if size not passed
    return <I {...props} className={cn} />
  }
})

Icon.displayName = "Icon"

export default Icon
