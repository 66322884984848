import cx from "classnames"
import React from "react"

interface Props {
  variant?: "primary" | "secondary"
  size?: "sm" | "md"
  className?: string
}

const Spinner: React.FC<Props> = ({ variant = "primary", size, className }) => {
  return (
    <div
      className={cx(
        "spinner-border",
        `spinner-border-${variant}`,
        {
          [`spinner-border-${size}`]: !!size,
        },
        className
      )}
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  )
}

export default Spinner
