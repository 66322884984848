import Banner from "app/components/Banner"
import NotFound from "app/components/NotFound"
import * as React from "react"

interface ErrorMessagePanelProps {
  code?: string
  title?: string
  body?: React.ReactNode
  ids?: string[]
}

const ErrorMessagePanel: React.FC<ErrorMessagePanelProps> = ({
  code,
  title,
  body,
  ids,
}) => {
  // We've got a nice 404 page, so use that if possible
  if (code === "NotFound") return <NotFound />

  title = title || "An Error Occurred"

  return (
    <Banner variant="danger" dismissable={false}>
      <h4>{title}</h4>
      <ErrorMessageBody body={body} code={code} ids={ids} />
    </Banner>
  )
}

export interface ErrorMessageBodyProps {
  body: React.ReactNode
  ids?: string[]
  code?: string
}

export const ErrorMessageBody: React.FC<ErrorMessageBodyProps> = ({
  body,
  ids,
  code,
}) => {
  return (
    <>
      <div className="mb-3">{body || "We apologize for the inconvenience"}</div>

      {code && <p className="mb-0 text-small">Type: {code}</p>}

      {!!ids?.length && (
        <div className="text-small">
          Reference IDs: {ids.join(", ")}
          <br />
        </div>
      )}
    </>
  )
}

export default ErrorMessagePanel
