import Icon, { IconName } from "app/components/icons/Icon"
import cx from "classnames"
import React, { useCallback, useState } from "react"

export type BannerVariant = "info" | "warning" | "danger" | "minimal"

interface Props {
  /**
   * `info` is default. `danger` and `warning` get special icons and colors.
   * `minimal` has no actions and no styling, and doesn't show actions.
   */
  variant?: BannerVariant
  /**
   * Use .alert-link for any links in here.
   */
  children: React.ReactNode
  /**
   * Up to two. Use link-styled things, not buttons. If there are actions,
   * should probably also be dismissable: false?
   */
  actions?: React.ReactNode
  /**
   * True by default. Pass false to hide dismiss button.
   */
  dismissable?: boolean
  /** Only for emergencies. */
  className?: string
  /**
   * Globally unique ID for banner if we want it to be permanently dismissable.
   * Not implemented yet.
   */
  id?: string
}

/**
 * Main banner component - wrapper for bootstrap .alert class.
 *
 * - primary - Default, actually uses "info" rather than "primary" styling. It's
 *   pretty noticeable, so use judiciously.
 * - warning - For important and bad things that are not errors.
 * - danger - For errors.
 *
 * If you need to be able to dismiss from outside the component or from the
 * actions use BannerControlled instead.
 */
const Banner: React.FC<Props> = props => {
  const [dismissed, setDismissed] = useState(false)
  const onDismiss = useCallback(() => setDismissed(true), [])

  return (
    <BannerControlled {...props} dismissed={dismissed} onDismiss={onDismiss} />
  )
}

interface BannerControlledProps extends Props {
  dismissed: boolean
  onDismiss(): void
}

/**
 * Controlled version of banner component, use if you need to be able to dismiss
 * from outside the component or from the actions.
 */
export const BannerControlled: React.FC<BannerControlledProps> = ({
  variant = "info",
  children,
  actions,
  dismissable = true,
  className,
  dismissed,
  onDismiss,
}) => {
  if (dismissed) return null

  if (variant === "minimal") {
    return (
      <div className="d-flex align-items-center mb-3">
        <div className="align-self-start">
          <Icon name="error" className="me-1" />
        </div>
        <div>{children}</div>
      </div>
    )
  }

  let fill: string // can move these to css if we want
  let iconName: IconName
  switch (variant) {
    case "info":
      fill = "var(--bs-cyan-700)"
      iconName = "info"
      break
    case "warning":
      fill = "var(--bs-yellow-700)"
      iconName = "error"
      break
    case "danger":
      fill = "var(--bs-red-700)"
      iconName = "emergency_home"
  }

  return (
    <div
      role="alert"
      className={cx("alert alert-banner", `alert-${variant}`, className, {
        "alert-dismissible": dismissable,
      })}
    >
      <div className="alert-icon">
        <Icon name={iconName} fill={fill} className="me-1" />
      </div>

      <div className="alert-content">
        {children}
        {!!actions && <div className="alert-actions">{actions}</div>}
      </div>

      {dismissable && (
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={onDismiss}
        />
      )}
    </div>
  )
}

export default Banner
