import { NavItemProps } from "app/apps/main/nav/SideNav"
import ArrowRight from "app/images/arrow-right.svg"
import Folder from "app/images/folder.png"
import cx from "classnames"
import React from "react"
import { Link } from "react-router-dom"

interface Props {
  navItem: NavItemProps
  selected: boolean
}

const NavItem: React.FC<Props> = ({ navItem, selected }) => {
  const iconSrc = navItem.icon || Folder

  return (
    <div
      className={cx("active:bg-blue-600 active:text-white", {
        "bg-gray-200": selected,
      })}
    >
      <Link to={navItem.path}>
        <div className="flex flex-nowrap py-1 pl-[6px] items-center">
          <img src={iconSrc} width={24} height={24} />
          <span className="mr-auto ml-1">{navItem.title}</span>

          <img src={ArrowRight} width={24} height={24} />
        </div>
      </Link>
    </div>
  )
}

export default NavItem
