import { useGlobalState } from "app/apps/main/GlobalStateProvider"
import NavItem from "app/apps/main/nav/NavItem"
import React, { useEffect } from "react"
import { matchPath, useLocation } from "react-router-dom"

export type NavItemProps = {
  title: string
  path: string
  icon?: string
  children?: NavItemProps[]
}

const navItems: NavItemProps[] = [
  {
    title: "welcome",
    path: "/welcome",
  },
  {
    title: "blog",
    path: "/blog",
  },
  {
    title: "stuff i like",
    path: "/stuff-i-like",
    children: [
      {
        title: "products",
        path: "/stuff-i-like/products",
      },
      {
        title: "resources",
        path: "/stuff-i-like/resources",
      },
    ],
  },
  {
    title: "lab",
    path: "/lab",
  },
  {
    title: "contact",
    path: "/contact",
  },
]

const SideNav: React.FC = () => {
  const { navOpen, navLocked, setGlobalState } = useGlobalState()
  const { pathname } = useLocation()

  const selectedNavItem = navItems.find(navItem => {
    // if (pathname === navItem.path) {
    //   return true
    // } else if (navItem.children) {
    //   return !!navItem.children.find(child => child.path === pathname)
    // }
    // return false
    let path = [navItem.path]
    if (navItem.children) {
      path = path.concat(navItem.children.map(child => child.path))
    }
    return !!matchPath(pathname, { path })
  })

  // TODO: move the match logic further up, into some editor-specific global UI
  // thing
  useEffect(() => {
    setGlobalState({ selectedNavItem })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNavItem])

  return (
    <div id="sidenav-wrapper">
      <nav id="sidenav" className="border-r-2">
        {navItems.map(navItem => (
          <NavItem
            key={navItem.title}
            selected={selectedNavItem === navItem}
            navItem={navItem}
          />
        ))}
      </nav>

      {selectedNavItem?.children && <SideNav2 navItem={selectedNavItem} />}
    </div>
  )
}

interface SideNav2Props {
  navItem: NavItemProps
}
const SideNav2: React.FC<SideNav2Props> = ({ navItem }) => {
  const { pathname } = useLocation()

  if (!navItem.children) {
    return null
  }

  const selectedChild = navItem.children.find(item => {
    return pathname === item.path
  })

  return (
    <nav id="sidenav" className="border-r-2">
      {navItem.children.map(item => (
        <NavItem
          key={item.title}
          selected={selectedChild === item}
          navItem={item}
        />
      ))}
    </nav>
  )
}

export default SideNav
