// FYI: This is NOT SECURE
// When logging out, localStorage isn't cleared, so any preferences the user has
// saved in here will not be cleared and will effectively be public.
//
// Alternatives:
// - The user loses all preferences when they log out (bad)
// - We persist these somewhere (kind of a pain)

const prefix = "jg:SavedState"

function store(isSession = false) {
  return isSession ? window.sessionStorage : window.localStorage
}

export function loadState<T = unknown>(
  key: string,
  isSession = false
): T | undefined {
  // TODO: this should probably try both sessionStorage and localStorage
  const serializedState = store(isSession).getItem(`${prefix}:${key}`)
  if (serializedState) {
    try {
      const state = JSON.parse(serializedState) as T
      return state
    } catch (ex) {
      console.error(ex, key, serializedState)
    }
  }
  return undefined
}

export function saveState(key: string, value: unknown, isSession = false) {
  try {
    // undefined doesn't like being json
    if (value === undefined) value = null
    store(isSession).setItem(`${prefix}:${key}`, JSON.stringify(value))
  } catch (ex) {
    console.error(ex, key, value)
  }
}

export function clearState(key: string, isSession = false) {
  store(isSession).removeItem(`${prefix}:${key}`)
}
