import { loadState } from "app/services/savedState"
import React, { createContext, useContext, useState } from "react"

export type Breadcrumb = {
  title: string
  path?: string
  inTitle?: boolean
}

type GlobalState = {
  breadcrumbs: Breadcrumb[]
  isEditing: boolean
  /** user-preference for whether to expand secondary side nav */
  navOpenSetting: boolean
  selectedNavItem?: { children?: unknown[] }
  modalProps: { show: boolean }
}

const GlobalStateContext = createContext<
  GlobalState & {
    navLocked: boolean
    navOpen: boolean
    setGlobalState: (state: Partial<GlobalState>) => void
  }
>(undefined as never)

interface Props {
  children: React.ReactNode
}

export const GlobalStateProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = useState<GlobalState>({
    breadcrumbs: [],
    isEditing: false,
    navOpenSetting: loadState("navOpenSetting") ?? window.innerWidth > 1159,
    selectedNavItem: undefined,
    modalProps: { show: false },
  })

  const setGlobalState = (newState: Partial<GlobalState>) => {
    setState({ ...state, ...newState })
  }

  const navLocked = !state.selectedNavItem?.children?.length
  const navOpen = state.navOpenSetting && !navLocked

  return (
    <GlobalStateContext.Provider
      value={{ ...state, navLocked, navOpen, setGlobalState }}
    >
      {children}
    </GlobalStateContext.Provider>
  )
}

export const useGlobalState = () => useContext(GlobalStateContext)
