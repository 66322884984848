import ErrorBoundary from "app/components/wrappers/ErrorBoundary"
import React from "react"
import {
  Route,
  RouteComponentProps,
  RouteProps,
  Switch,
} from "react-router-dom"

// TODO: support passing a subpath which appends itself to the path in context
export interface ERouteProps extends RouteProps {
  /**
   * Puts a "\<Switch\>" component directly inside the route. Set to true if this
   * route's children are themselves routes and exactly one of them should
   * match.
   */
  exclusive?: boolean
  children?: React.ReactNode
}

/**
 * Wraps a <Route> with error handling, tracking, and other metadata.
 */
const ERoute: React.FC<ERouteProps> = ({
  render,
  children,
  exclusive,
  ...routeProps
}) => {
  if (children && render) {
    throw "Pass only one of children and render"
  } else if (!children && !render) {
    throw "Pass either children or render"
  }

  return (
    <ErrorBoundary>
      <Route
        {...routeProps}
        render={(renderProps: RouteComponentProps) => {
          return (
            // could wrap this with a tracking provider to get page views
            <ERouteInner
              {...renderProps}
              render={render || (() => children)}
              exclusive={!!exclusive}
            />
          )
        }}
      />
    </ErrorBoundary>
  )
}

export interface InnerProps extends RouteComponentProps {
  exclusive: boolean
  children?: React.ReactNode
  render(props: RouteComponentProps): React.ReactNode
}

const ERouteInner: React.FC<InnerProps> = ({
  render,
  exclusive,
  ...renderProps
}) => {
  // usePageView(trackPage)
  return (
    <>
      {exclusive ? <Switch>{render(renderProps)}</Switch> : render(renderProps)}
    </>
  )
}

export default ERoute
