import distn from "app/images/distn.png"
import logo from "app/images/favicon.png"
import cx from "classnames"
import React, { useState } from "react"
import { Link } from "react-router-dom"

const TopNav: React.FC = () => {
  // const toggleEnvColoring = useToggleAppearance(setEnv)
  const [show, _setShow] = useState(true)
  const headerText = "jonah.golden"

  return (
    <nav
      id="topnav"
      className={cx("fixed-top drop-in border-b-2", { show })}
      // onDoubleClick={toggleEnvColoring}
    >
      <div className="flex items-center">
        <div className="flex items-center p-3 shrink-0">
          <Link to="/" className="mr-4">
            <img src={logo} alt="Jonah Lab" width={70} />
          </Link>
          <div className="text-4xl">{headerText}</div>
        </div>

        {Array.from(Array(20).keys()).map((_, i) => (
          <img key={`distn-${i}`} src={distn} width={101} height={94} />
        ))}
      </div>
    </nav>
  )
}

export default TopNav
