import React from "react"

const Blog: React.FC = () => {
  return (
    <div>
      <article>
        <main></main>
      </article>
    </div>
  )
}

export default Blog
