import { GlobalStateProvider } from "app/apps/main/GlobalStateProvider"
import Layout from "app/apps/main/Layout"
import "app/types"
import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
// import Layout from "app/apps/main/Layout"
// import ToastMaster from "app/components/ui/ToastMaster"
// import TrackingProvider from "app/components/wrappers/TrackingProvider"
// import BreadcrumbProvider from "app/components/wrappers/BreadcrumbProvider"
// import ConsoleHelper from "app/apps/main/ConsoleHelper"
// import InitialToasts from "app/apps/main/InitialToasts"
// import { UnviewedReleaseProvider } from "app/components/wrappers/UnviewedReleaseProvider"

const App: React.FC<Obj> = () => {
  return (
    <React.StrictMode>
      <GlobalStateProvider>
        <Router>
          <Layout />
        </Router>

        {/* <TrackingProvider breadcrumbs={[{ title: "Allumia", path: "/" }]}>
            <Router>
              <ToastMaster>
                <InitialToasts />
                
                  <BreadcrumbProvider>
                    <ConsoleHelper />
                    <Layout />
                  </BreadcrumbProvider>
                
              </ToastMaster>
            </Router>
          </TrackingProvider> */}
      </GlobalStateProvider>
    </React.StrictMode>
  )
}

export default App
