import organic from "app/images/organic.jpg"
import React from "react"
import { Link } from "react-router-dom"

// Show this for 404's, e.g. a bad route, or a record not found
// NB!: This component needs to stay in sync with public/404.html
const NotFound: React.FC = ({ to = "/" }: { to?: string }) => {
  return (
    <div className="not-found-error-wrapper">
      <h1>404</h1>

      <img src={organic} height={240} />

      <h3>Oops, it looks like the lights are out over here.</h3>

      <Link className="btn btn-lg btn-primary" to={to}>
        Get Plugged Back In
      </Link>
    </div>
  )
}

export default NotFound
