import ErrorMessagePanel from "app/components/ErrorMessagePanel"
import * as React from "react"

export interface ErrorStateProps {
  error?: Error
  size: "page" | "element" | "mini"
}

interface Props {
  size?: "page" | "element" | "mini"
  children: React.ReactNode
  /** Optional onError callback */
  error?(props: ErrorStateProps): React.ReactChild
}

interface State {
  hasError: boolean
  error?: Error
  errorId?: string
}

export default class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.error(error, info)
  }

  render() {
    if (this.state.hasError) {
      if (this.props.error) {
        return this.props.error({
          error: this.state.error,
          size: this.props.size || "page",
        })
      } else {
        return <ErrorMessagePanel />
      }
    }

    return this.props.children || ""
  }
}
