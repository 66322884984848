import Blog from "app/apps/blog/Blog"
import ERoute from "app/components/wrappers/ERoute"
import React from "react"
import { Switch } from "react-router-dom"

const Routes: React.FC = () => {
  return (
    <Switch>
      <ERoute path="/welcome" exact>
        <div>Home</div>
      </ERoute>
      <ERoute path="/blog" exact>
        <Blog />
      </ERoute>
      <ERoute path="/stuff-i-like/products" exact>
        <div>a few of my favorite things</div>
      </ERoute>
      <ERoute path="/stuff-i-like/resources" exact>
        <div>table</div>
      </ERoute>
      <ERoute path="/lab" exact>
        <div>login</div>
      </ERoute>
      <ERoute path="/contact" exact>
        <div>jonah</div>
      </ERoute>
    </Switch>
  )
}

export default Routes
