import Routes from "app/apps/main/Routes"
import SideNav from "app/apps/main/nav/SideNav"
import TopNav from "app/apps/main/nav/TopNav"
import Spinner from "app/components/Spinner"
import * as React from "react"

/**
 * Top level layout
 * In the future, could decide which layout/app to render based on user role
 * and lazy load the layouts as a form of code splitting
 */
const Layout: React.FC = () => {
  // const {navOpen} = useGlobalState()
  return (
    <div>
      <header className="border-b-1 overflow-hidden">
        <TopNav />
      </header>
      <div className="flex">
        <div className="font-medium">
          <SideNav />
        </div>
        <main
          id="main"
          // className={cx({
          //   "secondary-sidenav-expanded": navOpen,
          // })}
        >
          <div className="container-fluid">
            {/* <Breadcrumbs /> */}
            <React.Suspense fallback={<Spinner size="md" />}>
              <Routes />
            </React.Suspense>
          </div>
        </main>
      </div>
    </div>
  )
}

// interface GuestLayoutProps {}

// const LabLayout: React.FC<GuestLayoutProps> = () => {
//   return (
//     <GuestNavProvider>
//       <header>
//         <GuestNav />
//       </header>

//       <div id="lab-main" className="has-header">
//         <React.Suspense fallback={<Spinner size="page" />}>
//           <LabRoutes />
//         </React.Suspense>
//       </div>
//     </GuestNavProvider>
//   )
// }

export default Layout
